import { useState, useMemo } from 'react';
import { useAppSelector } from '../app/hooks';
import { TabDictionary } from '../interfaces/TabDictionary';
import InformationSection from './InformationSection';
import AnalyticsDashboard from './AnalyticsDashboard';
import ReportGeneration from './ReportGeneration';

export default function AnalyticsInformation() {
  const analyticsTabs: TabDictionary = useMemo(() => ({
    dashboard: {
      desktopValue: 'Analytics Dashboard',
      mobileValue: 'Dashboard',
      component: (
        <AnalyticsDashboard />
      ),
    },
    reports: {
      desktopValue: 'Generate Reports',
      mobileValue: 'Reports',
      component: (
        <ReportGeneration />
      ),
    },
  }), []);

  return (
    <div>
      <>
        {Object.keys(analyticsTabs).length > 0 && (
          <InformationSection
            tabs={analyticsTabs}
            initialState="dashboard"
          />
        )}
      </>

    </div>
  );
}
