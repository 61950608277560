/* eslint-disable jsx-a11y/label-has-associated-control */
import { useLazyQuery, useQuery } from '@apollo/client';
import { ChangeEvent, useEffect, useState } from 'react';
import {
  LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend,
} from 'recharts';
import styles from '../sass/components/Analytics.module.scss';
import {
  GET_FRANCHISE_ANALYTICS,
  GET_FRANCHISES_VANS_OF_FRANCHISE_MANAGER,
  GET_ALL_ANALYTICS,
} from '../util/gql';
import { useAppSelector } from '../app/hooks';
import { Van } from '../interfaces/Van';
import { FranchiseVans } from '../interfaces/WorkOrder';
import Select from './Select';
import PieChartComponent from './PieChart';
import ReusableBarChart from './BarChart';
import {
  BaseAnalytics,
  ExtendedAnalytics,
} from '../interfaces/AnalyticsDashboard';
import {
  getStartOfYear,
  toCST,
  toUTC,
} from '../util/dateHelper';
import Checkbox from './Checkbox';

export default function AnalyticsDashboard() {
  const { franchiseIds } = useAppSelector((state) => state.auth.currentUser);
  const { id: userId } = useAppSelector((state) => state.auth.currentUser);
  const [baseAnalytics, setBaseAnalytics] = useState<BaseAnalytics>();
  const [selectedFranchises,
    setSelectedFranchises] = useState<number[]>(franchiseIds || []);
  const [selectedVans,
    setSelectedVans] = useState<number[]>([]);
  const [franchises, setFranchises] = useState<FranchiseVans[]>([]);
  const [vans, setVans] = useState<Van[]>([]);
  const [extendedAnalyticsData,
    setExtendedAnalyticsData] = useState<ExtendedAnalytics>();
  const [isChecked, setIsChecked] = useState<boolean>(false);
  const [selectedFilter, setSelectedFilter] = useState<string>('workOrders');
  const getCurrentDateTime = () => {
    const now = new Date();
    return now.toISOString().slice(0, 16);
  };

  const [startDate, setStartDate] = useState(getStartOfYear());
  const [endDate, setEndDate] = useState(getCurrentDateTime());

  const {
    data: baseAnalyticsDetails,
  } = useQuery(GET_FRANCHISE_ANALYTICS, {
    variables: { franchiseIds },
    fetchPolicy: 'network-only',
  });

  const [getAllAnalytics] = useLazyQuery(GET_ALL_ANALYTICS, {
    onCompleted: (data) => {
      setExtendedAnalyticsData(data.getAllAnalytics);
    },
    fetchPolicy: 'no-cache',
  });

  const salesData = extendedAnalyticsData?.salesByDays.map((item) => ({
    date: item.date ? toCST(item.date.toString()) : '',
    total: item.total / 100,
  }));

  const revenueData = extendedAnalyticsData?.revenueByDays.map((item) => ({
    date: item.date ? toCST(item.date.toString()) : '',
    total: item.total / 100,
  }));

  // eslint-disable-next-line max-len
  const profitMarginData = extendedAnalyticsData?.profitMarginByDays?.map((item) => ({
    date: item.date ? toCST(item.date.toString()) : '',
    billedAmount: item.billedAmount / 100,
    subtotal: item.subtotal / 100,
    tax: item.tax / 100,
    inventoryCosts: (item?.inventoryCosts ?? 0) / 100 || 0,
    staffCost: (item?.staffCost ?? 0) / 100 || 0,
    fuelCost: (item.fuelCost ?? 0) / 100,
    profit: item.profit / 100,
  })) ?? [];

  // eslint-disable-next-line max-len
  const percentagesProfit = extendedAnalyticsData?.profitMarginByDays?.map((item) => ({
    billedAmountPercentage: 100,
    inventoryCostPercentage: Math.round(((
      item?.inventoryCosts ?? 0
    ) / item.billedAmount) * 100),
    staffCostPercentage: Math.round(((
      item?.staffCost ?? 0) / item.billedAmount) * 100),
    fuelCostPercentage: Math.round((
      (item?.fuelCost ?? 0) / item.billedAmount) * 100),
    profitPercentage: Math.round((item.profit / item.billedAmount) * 100),
    taxPercentage: Math.round((item.tax / item.billedAmount) * 100),
  })) ?? [];

  const paymentTypesData = extendedAnalyticsData?.paymentTypes?.map((item) => ({
    paymentMethod: item.paymentMethod,
    count: item.count,
  })) ?? [];

  // eslint-disable-next-line max-len
  const vendorCustomerAmount = extendedAnalyticsData?.vendorCustomers?.vendorCustomers ?? 0;

  useEffect(() => {
    if (selectedFranchises && startDate && endDate && selectedFilter) {
      getAllAnalytics({
        variables: {
          selectedFranchises,
          selectedVans,
          startDate,
          endDate,
          filter: selectedFilter,
        },
      })
        .catch((error) => {
          console.error(
            'Error triggering getAllAnalytics:',
            error,
          );
        });
    }
  }, [selectedFranchises,
    selectedVans,
    startDate,
    endDate,
    selectedFilter,
    getAllAnalytics]);

  useQuery(GET_FRANCHISES_VANS_OF_FRANCHISE_MANAGER, {
    fetchPolicy: 'network-only',
    variables: {
      userId,
    },
    onCompleted: (data) => {
      if (data.getFranchisesVansOfFranchiseManager) {
        setFranchises(data.getFranchisesVansOfFranchiseManager);
      }
    },
  });

  useEffect(() => {
    const calculatePercentChange = (
      currentValue: number, lastMonthValue: number,
    ): string => {
      if (lastMonthValue === 0 && currentValue > 0) {
        return '100';
      } if (lastMonthValue === 0 && currentValue === 0) {
        return '0';
      } if (lastMonthValue === 0 && currentValue < 0) {
        return '-100';
      }
      const percentChange = (((currentValue - lastMonthValue)
      / lastMonthValue) * 100).toFixed(2);
      return percentChange;
    };

    if (baseAnalyticsDetails) {
      const details = baseAnalyticsDetails.getFranchiseAnalytics;

      if (!details) {
        return;
      }

      const adjustedDetails: BaseAnalytics = {
        totalSales: details.totalSales / 100,
        totalSalesLastMonth: details.totalSalesLastMonth / 100,
        percentChangeSales: parseFloat(calculatePercentChange(
          details.totalSales,
          details.totalSalesLastMonth,
        )),
        totalRevenue: details.totalRevenue / 100,
        totalRevenueLastMonth: details.totalRevenueLastMonth / 100,
        percentChangeRevenue: parseFloat(calculatePercentChange(
          details.totalRevenue,
          details.totalRevenueLastMonth,
        )),
        percentChangeOilChanges: parseFloat(calculatePercentChange(
          details.totalOilChanges,
          details.totalOilChangesLastMonth,
        )),
        percentChangeWorkOrders: parseFloat(calculatePercentChange(
          details.totalWorkOrders,
          details.totalWorkOrdersLastMonth,
        )),
        totalWorkOrders: details.totalWorkOrders,
        totalWorkOrdersLastMonth: details.totalWorkOrdersLastMonth,
        totalOilChanges: details.totalOilChanges,
        totalOilChangesLastMonth: details.totalOilChangesLastMonth,
      };

      setBaseAnalytics(adjustedDetails);
    }
  }, [baseAnalyticsDetails]);

  const handleFranchiseChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const value = Array.from(
      e.target.selectedOptions, (option) => option.value,
    );

    if (value.includes('all')) {
      setSelectedFranchises(franchises.map((franch) => franch.id));
    } else {
      const selectedIds = value.map((val) => parseInt(val, 10));
      setSelectedFranchises(selectedIds);

      const selectedVan = franchises
        .filter((franchise) => selectedIds.includes(franchise.id))
        .flatMap((franchise) => franchise.vans || []);
      setVans(selectedVan);
    }
  };

  const handleVanChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const value = Array.from(
      e.target.selectedOptions, (option) => option.value,
    );

    if (value.includes('all')) {
      const selectedFranchiseVans = franchises.find(
        (f) => selectedFranchises.includes(f.id),
      );

      const selectedVansAll = selectedFranchiseVans
        ? selectedFranchiseVans.vans.map((van) => van.id)
        : [];
      setSelectedVans(selectedVansAll);
    } else {
      setSelectedVans(value.map((val) => parseInt(val, 10)));
    }
  };

  const handleFilterChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedFilter(event.target.value);
  };

  const handleCheckbox = (prevState: boolean): boolean => !prevState;

  const toggleCheckbox = () => {
    setIsChecked((prev) => handleCheckbox(prev));
  };

  return (
    <div className={styles.customOverride}>
      <div className={styles.customerInformation}>
        <h4 className={styles.paymentHeader}>
          Analytics Dashboard
        </h4>
      </div>
      <div className={styles.overviewCaption}>
        <h5>General Franchise Overview</h5>
        <p>
          This is a general overview of some live metrics showing how the
          markets / market are performing this month so far compared
          to the same time last month.
        </p>
      </div>
      <div className={styles.gridBody}>
        <div className={styles.gridContainer}>
          <div className={styles.gridItem}>
            <span className={styles.metricLabel}>Total Sales:</span>
            {' $'}
            {baseAnalytics?.totalSales}
            <div className={styles.metricChange}>
              {baseAnalytics?.percentChangeSales}
              %
            </div>
          </div>
          <div className={styles.gridItem}>
            <span className={styles.metricLabel}>Total Revenue:</span>
            {' $'}
            {baseAnalytics?.totalRevenue}
            <div className={styles.metricChange}>
              {baseAnalytics?.percentChangeRevenue}
              %
            </div>
          </div>
          <div className={styles.gridItem}>
            <span className={styles.metricLabel}>Total Oil Changes:</span>
            {' '}
            {baseAnalytics?.totalOilChanges}
            <div className={styles.metricChange}>
              {baseAnalytics?.percentChangeOilChanges}
              %
            </div>
          </div>
          <div className={styles.gridItem}>
            <span className={styles.metricLabel}>Total Work Orders:</span>
            {' '}
            {baseAnalytics?.totalWorkOrders}
            <div className={styles.metricChange}>
              {baseAnalytics?.percentChangeWorkOrders}
              %
            </div>
          </div>
        </div>
      </div>

      <div className={styles.filterContainer}>
        {franchises.length > 1 && (
          <Select
            label=""
            loading={false}
            id="Franchise Select"
            placeholder="Select a Franchise"
            value={selectedFranchises.join(',')}
            onChange={handleFranchiseChange}
          >
            <option key="all" value="all">
              All Franchises
            </option>
            {franchises.map((franch) => (
              <option key={franch.id} value={franch.id.toString()}>
                {selectedFranchises.includes(franch.id)
                  ? `${franch.name} (currently selected)`
                  : franch.name}
              </option>
            ))}
          </Select>
        )}
        {vans.length > 1 && (
          <Select
            label=""
            loading={false}
            id="Vans Select"
            placeholder="Select a Van"
            value={selectedVans.join(',')}
            onChange={handleVanChange}
          >
            <option key="all" value="all">
              All Vans
            </option>
            {vans.map((van) => (
              <option key={van.id} value={van.id.toString()}>
                {selectedVans.includes(van.id)
                  ? `${van.name} (currently selected)`
                  : van.name}
              </option>
            ))}
          </Select>
        )}
        <Select
          label=""
          loading={false}
          id="Filter Select"
          placeholder="Select Filter Option"
          value={selectedFilter}
          onChange={handleFilterChange}
        >
          <option key="workOrders" value="workOrders">
            Group by Work Orders
          </option>
          <option key="days" value="days">Group by Days</option>
          <option key="months" value="months">Group by Months</option>
        </Select>

        <div style={{ display: 'flex', gap: '0.5rem', alignItems: 'center' }}>
          <label htmlFor="startDate">From:</label>
          <input
            type="datetime-local"
            id="startDate"
            value={toCST(startDate)}
            onChange={(e) => setStartDate(toUTC(e.target.value))}
          />
          <label htmlFor="endDate">To:</label>
          <input
            type="datetime-local"
            id="endDate"
            value={toCST(endDate)}
            onChange={(e) => setEndDate(toUTC(e.target.value))}
          />
        </div>
        <div style={{ display: 'flex', gap: '0.5rem', alignItems: 'center' }}>
          Profit Data in %
          <Checkbox
            isChecked={isChecked}
            onChange={toggleCheckbox}
          />
        </div>
      </div>
      <div>
        {extendedAnalyticsData ? (
          <>
            <div className={styles.analyticsDashboard}>
              <div className={styles.chartContainer}>
                <h3>Sales Over Time</h3>
                <LineChart
                  width={400}
                  height={250}
                  data={salesData}
                  margin={{
                    top: 10,
                    right: 20,
                    left: 0,
                    bottom: 20,
                  }}
                >
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis
                    dataKey="date"
                    tickFormatter={
                  (tick) => new Date(tick).toLocaleString()
                  }
                  />
                  <YAxis />
                  <Tooltip labelFormatter={
                  (label) => (label).toLocaleString()
                  }
                  />
                  <Legend />
                  <Line
                    type="monotone"
                    dataKey="total"
                    stroke="#8884d8"
                    activeDot={{ r: 6 }}
                  />
                </LineChart>
              </div>
              <div className={styles.chartContainer}>
                <h3>Revenue Over Time</h3>
                <LineChart
                  width={400}
                  height={250}
                  data={revenueData}
                  margin={{
                    top: 10,
                    right: 20,
                    left: 0,
                    bottom: 20,
                  }}
                >
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="date" />
                  <YAxis />
                  <Tooltip />
                  <Legend />
                  <Line
                    type="monotone"
                    dataKey="total"
                    stroke="#4C72B0"
                    activeDot={{ r: 6 }}
                  />
                </LineChart>
              </div>
            </div>
            <div className={styles.analyticsDashboard}>
              <div className={styles.chartContainer}>
                {!isChecked ? (
                  <ReusableBarChart
                    title="Profit Margin Over Time"
                    data={profitMarginData}
                    xKey="date"
                    bars={[
                      {
                        key: 'inventoryCosts',
                        color: '#C44E52',
                        name: 'Inventory Cost',
                        stackId: 'expenses',
                      },
                      {
                        key: 'staffCost',
                        color: '#8172B3',
                        name: 'Staff Cost',
                        stackId: 'expenses',
                      },
                      {
                        key: 'fuelCost',
                        color: '#937860',
                        name: 'Fuel Cost',
                        stackId: 'expenses',
                      },
                      {
                        key: 'tax',
                        color: '#55A868',
                        name: 'Tax',
                        stackId: 'expenses',
                      },
                      {
                        key: 'billedAmount',
                        color: '#4C72B0',
                        name: 'Billed Amount',
                      },
                      {
                        key: 'profit',
                        color: '#64B5CD',
                        name: 'Profit',
                        stackId: 'profitStack',
                      },
                    ]}
                  />
                ) : (
                  <ReusableBarChart
                    title="Profit Margin Over Time"
                    data={percentagesProfit}
                    xKey="date"
                    bars={[
                      {
                        key: 'inventoryCostPercentage',
                        color: '#C44E52',
                        name: 'Inventory Cost Percentage',
                        stackId: 'expenses',
                      },
                      {
                        key: 'staffCostPercentage',
                        color: '#8172B3',
                        name: 'Staff Cost Percentage',
                        stackId: 'expenses',
                      },
                      {
                        key: 'fuelCostPercentage',
                        color: '#937860',
                        name: 'Fuel Cost Percentage',
                        stackId: 'expenses',
                      },
                      {
                        key: 'taxPercentage',
                        color: '#55A868',
                        name: 'Tax Percentage',
                        stackId: 'expenses',
                      },
                      {
                        key: 'billedAmountPercentage',
                        color: '#4C72B0',
                        name: 'Billed Amount Percentage',
                      },
                      {
                        key: 'profitPercentage',
                        color: '#64B5CD',
                        name: 'Profit Percentage',
                        stackId: 'profitStack',
                      },
                    ]}
                  />
                )}
              </div>
              <div className={styles.chartContainer}>
                <ReusableBarChart
                  title="Most Used Payment Methods"
                  data={paymentTypesData}
                  xKey="paymentMethod"
                  bars={[
                    { key: 'count', color: '#4C72B0', name: 'Usage Count' },
                  ]}
                />
              </div>
            </div>
            <div className={styles.analyticsDashboard}>
              <div className={styles.chartContainer}>
                <PieChartComponent
                  title="New vs Recurring Customers"
                  data={[{
                    name: 'New Customers',
                    value: extendedAnalyticsData?.recurringCustomers ?? 0,
                  },
                  {
                    name: 'Recurring Customers',
                    value: extendedAnalyticsData?.newCustomers ?? 0,
                  }]}
                  colors={['#4C72B0', '#64B5CD']}
                />
              </div>
              <div className={styles.chartContainer}>
                <PieChartComponent
                  title="Residential vs Commercial Customers"
                  data={[{
                    name: 'Residential Customers',
                    value: extendedAnalyticsData?.residentialCustomers ?? 0,
                  },
                  {
                    name: 'Commercial Customers',
                    value: vendorCustomerAmount ?? 0,
                  }]}
                  colors={['#4C72B0', '#64B5CD']}
                />
              </div>
            </div>
          </>
        ) : (
          <p>Loading analytics...</p>
        )}
      </div>
    </div>
  );
}
