export const formatDateForInput = (
  date: Date,
) => date.toISOString().slice(0, 16);

export const getStartOfYear = () => {
  const startOfYear = new Date(new Date().getFullYear(), 0, 1);
  return formatDateForInput(startOfYear);
};

export const toUTC = (localDateString: string) => {
  if (!localDateString) return '';
  const localDate = new Date(localDateString);

  const year = localDate.getUTCFullYear();
  const month = String(localDate.getUTCMonth() + 1).padStart(2, '0');
  const day = String(localDate.getUTCDate()).padStart(2, '0');
  const hours = String(localDate.getUTCHours()).padStart(2, '0');
  const minutes = String(localDate.getUTCMinutes()).padStart(2, '0');

  return `${year}-${month}-${day}T${hours}:${minutes}`;
};

export const toCST = (localDateString: string) => {
  if (!localDateString) return '';
  const localDate = new Date(localDateString);
  const cstDate = new Date(localDate.getTime() - 6 * 60 * 60 * 1000);
  const year = cstDate.getFullYear();
  const month = String(cstDate.getMonth() + 1).padStart(2, '0');
  const day = String(cstDate.getDate()).padStart(2, '0');
  const hours = String(cstDate.getHours()).padStart(2, '0');
  const minutes = String(cstDate.getMinutes()).padStart(2, '0');

  return `${year}-${month}-${day}T${hours}:${minutes}`;
};

export const toCstCdt = (utcDateString: string) => {
  if (!utcDateString) return '';

  const utcDate = new Date(utcDateString);

  const cstDateString = utcDate.toLocaleString('en-US', {
    timeZone: 'America/Chicago',
    hour12: false,
  });
  return cstDateString;
};
