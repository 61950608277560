/* eslint-disable jsx-a11y/label-has-associated-control */
import { useLazyQuery, useQuery } from '@apollo/client';
import { useEffect, useState } from 'react';
import styles from '../sass/components/Analytics.module.scss';
import {
  GET_FRANCHISES_VANS_OF_FRANCHISE_MANAGER,
  GET_AR_REPORT,
  GET_ALL_AR_REPORT,
  GET_PAYOUT_REPORTS,
  GET_FRANCHISE_VENDOR_LIST,
} from '../util/gql';
import { useAppSelector } from '../app/hooks';
import { Van } from '../interfaces/Van';
import { FranchiseVans } from '../interfaces/WorkOrder';
import Select from './Select';
import CSVDownloadButton from './CsvDownloadButton';
import {
  PayoutReport,
  VendorReturn,
} from '../interfaces/AnalyticsDashboard';
import {
  toCstCdt,
  getStartOfYear,
  toCST,
  toUTC,
} from '../util/dateHelper';

export default function ReportGeneration() {
  const { franchiseIds } = useAppSelector((state) => state.auth.currentUser);
  const { id: userId } = useAppSelector((state) => state.auth.currentUser);
  const [selectedFranchisesAr,
    setSelectedFranchisesAr] = useState<number[]>([]);
  const [selectedVendor,
    setSelectedVendor] = useState<number[]>([]);
  const [selectedVansReport,
    setSelectedVansReport] = useState<number[]>([]);
  const [vendorList, setVendorList] = useState<VendorReturn[]>([]);
  const [franchisesAr,
    setFranchisesAr] = useState<FranchiseVans[]>([]);
  const [vansReport, setVansReport] = useState<Van[]>([]);
  const getCurrentDateTime = () => {
    const now = new Date();
    return now.toISOString().slice(0, 16);
  };
  const [arReport, setArReport] = useState<any>(null);
  const [allArReport, setAllArReport] = useState<any>(null);
  const [payoutReports, setPayoutReports] = useState<PayoutReport[]>([]);
  const [loading, setLoading] = useState<boolean>(false);

  const [startDateArReport,
    setStartDateArReport] = useState(getStartOfYear());
  const [endDateArReport, setEndDateArReport] = useState(getCurrentDateTime());

  const [getArReport] = useLazyQuery(GET_AR_REPORT, {
    onCompleted: (data) => {
      setArReport(data.getArReport);
      setLoading(false);
    },
    fetchPolicy: 'no-cache',
    onError: () => {
      setLoading(false);
    },
  });

  const [getVendorList] = useLazyQuery(GET_FRANCHISE_VENDOR_LIST, {
    onCompleted: (data) => {
      setVendorList(data.getFranchiseVendorList);
    },
  });

  const [getAllArReport] = useLazyQuery(GET_ALL_AR_REPORT, {
    onCompleted: (data) => {
      setAllArReport(data.getAllArReport);
      setLoading(false);
    },
    fetchPolicy: 'no-cache',
    onError: () => {
      setLoading(false);
    },
  });

  const [getPayoutReports] = useLazyQuery(GET_PAYOUT_REPORTS, {
    onCompleted: (data) => {
      const payoutReportData = data?.getPayoutReports?.map((item: any) => ({
        id: item.id,
        franchise: item.franchise.name,
        van: item.van.name,
        startDate: item.startDate,
        endDate: item.endDate,
        totalSales: item.totalSales / 100,
        subtotalSales: item.subTotalSales / 100,
        totalRevenue: item.totalRevenue / 100,
        subtotalRevenue: item.subTotalRevenue / 100,
        totalTax: item.totalTax / 100,
        transactionFee: item.transactionFees / 100,
        marketingFee: item.marketingFee / 100,
        royaltyFee: item.royaltyFee / 100,
        insurance: item.insurance / 100,
        technologyFee: item.technologyFee / 100,
        thirdPartyFleetFee: item.thirdPartyFleetFee / 100,
        nationalFleetFee: item.nationalFleetFee / 100,
        creditGoOil: item.creditGoOil / 100,
        refunds: item.refunds / 100,
        cashPayments: item.cashPayments / 100,
        unreportedRevenue: item.unreportedRevenue / 100,
        total: item.total / 100,
        status: item.status,

      }));
      setPayoutReports(payoutReportData);
      setLoading(false);
    },
    fetchPolicy: 'no-cache',
    onError: () => {
      setLoading(false);
    },
  });

  useEffect(() => {
    if (franchiseIds
        && franchiseIds.length > 0
        && startDateArReport
        && endDateArReport) {
      getVendorList({
        variables: {
          franchiseIds,
          startDate: startDateArReport,
          endDate: endDateArReport,
        },
      });
    }
  }, [franchiseIds, startDateArReport, endDateArReport, getVendorList]);

  useEffect(() => {
    if (selectedVendor && selectedVendor.length > 0) {
      getArReport({
        variables: {
          vendorIds: selectedVendor,
          startDate: startDateArReport,
          endDate: endDateArReport,
        },
      });
    }
  }, [selectedVendor, startDateArReport, endDateArReport]);

  useEffect(() => {
    if (selectedVansReport && selectedVansReport.length > 0) {
      getPayoutReports({
        variables: {
          vanIds: selectedVansReport,
          startDate: startDateArReport,
          endDate: endDateArReport,
        },
      });
    }
  },
  [selectedVansReport, startDateArReport, endDateArReport]);

  useEffect(() => {
    if (selectedFranchisesAr && selectedFranchisesAr.length > 0) {
      getAllArReport({
        variables: {
          startDate: startDateArReport,
          endDate: endDateArReport,
          franchiseIds: selectedFranchisesAr,
        },
      });
    }
  }, [startDateArReport, endDateArReport, selectedFranchisesAr]);

  useQuery(GET_FRANCHISES_VANS_OF_FRANCHISE_MANAGER, {
    fetchPolicy: 'network-only',
    variables: {
      userId,
    },
    onCompleted: (data) => {
      if (data.getFranchisesVansOfFranchiseManager) {
        setFranchisesAr(data.getFranchisesVansOfFranchiseManager);
      }
    },
  });

  const handleARFranchiseChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const value = Array.from(
      e.target.selectedOptions, (option) => option.value,
    );

    if (value.includes('all')) {
      setSelectedFranchisesAr(franchisesAr.map((franch) => franch.id));
    } else {
      const selectedIds = value.map((val) => parseInt(val, 10));
      setSelectedFranchisesAr(selectedIds);
      const selectedVan = franchisesAr
        .filter((franchise) => selectedIds.includes(franchise.id))
        .flatMap((franchise) => franchise.vans || []);
      setVansReport(selectedVan);
    }
  };

  const handleVendorChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const value = Array.from(
      e.target.selectedOptions, (option) => option.value,
    );

    if (value.includes('all')) {
      setSelectedVendor(franchisesAr.map((franch) => franch.id));
    } else {
      const selectedIds = value.map((val) => parseInt(val, 10));
      setSelectedVendor(selectedIds);
    }
  };

  const handleVanChangeReport = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const value = Array.from(
      e.target.selectedOptions, (option) => option.value,
    );

    if (value.includes('all')) {
      // eslint-disable-next-line max-len
      const selectedFranchise = franchisesAr.find((f) => selectedFranchisesAr.includes(f.id));

      const selectedVansReportPayout = selectedFranchise
        ? selectedFranchise.vans.map((van) => van.id)
        : [];
      setSelectedVansReport(selectedVansReportPayout);
    } else {
      setSelectedVansReport(value.map((val) => parseInt(val, 10)));
    }
  };

  return (
    <div className={styles.customOverride}>
      <div className={styles.customerInformation}>
        <h4 className={styles.paymentHeader}>
          Generate Extended Reports
        </h4>
      </div>

      <div className={styles.overviewCaption}>
        <p>
          To generate a report, please select a time frame.
          By default, the system uses the start of the year to the current date.
        </p>
      </div>
      <div className={styles.filterContainer}>
        <div style={{ display: 'flex', gap: '0.5rem', alignItems: 'center' }}>
          <label htmlFor="startDate">From:</label>
          <input
            type="datetime-local"
            id="startDate"
            value={toCST(startDateArReport)}
            onChange={(e) => setStartDateArReport(toUTC(e.target.value))}
          />
          <label htmlFor="endDate">To:</label>
          <input
            type="datetime-local"
            id="endDate"
            value={toCST(endDateArReport)}
            onChange={(e) => setEndDateArReport(toUTC(e.target.value))}
          />
        </div>
      </div>
      <div className={styles.overviewCaption}>
        <h5>Get Commercial Customer AR</h5>
      </div>
      <div className={styles.filterContainer}>
        <Select
          label=""
          loading={false}
          id="Vendor Select"
          placeholder="Choose a Commercial Client"
          value={selectedVendor.join(',')}
          onChange={handleVendorChange}
        >
          {Array.isArray(vendorList) && vendorList.length > 1 && (
          <option key="all" value="all">
            All Vendors
          </option>
          )}
          {Array.isArray(
            vendorList,
          ) && vendorList.map((vendor: VendorReturn) => (
            <option key={vendor.vendorId} value={vendor.vendorId.toString()}>
              {selectedVendor.includes(vendor.vendorId)
                ? `${vendor.vendorName} (currently selected)`
                : vendor.vendorName}
            </option>
          ))}
        </Select>
        {arReport && (
        <CSVDownloadButton
          data={arReport}
          headers={[
            'Invoice Id',
            'Work Order Id',
            'Franchise Name',
            'Contact Name',
            'Contact Email',
            'Invoice Status',
            'Billed Amount',
            'Invoice Date',
          ]}
          filename="vendor_ar_report.csv"
          transformData={(row) => [
            row.invoiceId,
            row.workOrderId,
            row.franchiseName,
            row.contactName,
            row.contactEmail,
            row.status,
            row.billedAmount / 100,
            toCstCdt(row.createdAt),
          ]}
        />
        )}
      </div>
      <div className={styles.overviewCaption}>
        <h5>Select a Market or All Markets for Reports</h5>
      </div>
      <div className={styles.filterContainer}>
        {franchisesAr && (
        <Select
          label=""
          loading={false}
          id="AR Franchise Select"
          placeholder="Select a Franchise"
          value={selectedFranchisesAr.join(',')}
          onChange={handleARFranchiseChange}
        >
          <option key="all" value="all">
            All Franchises
          </option>
          {franchisesAr.map((franch) => (
            <option key={franch.id} value={franch.id.toString()}>
              {selectedFranchisesAr.includes(franch.id)
                ? `${franch.name} (currently selected)`
                : franch.name}
            </option>
          ))}
        </Select>
        )}
      </div>
      <div className={styles.gridBody}>
        <div className={styles.gridContainer2}>
          <div className={styles.gridItem}>
            <div className={styles.overviewCaption}>
              <h5>Get Market AR</h5>
            </div>
            {' '}
            { allArReport && allArReport.length > 0 && (
            <CSVDownloadButton
              data={allArReport}
              headers={['Invoice Id',
                'Work Order Id',
                'Franchise Name',
                'Contact Name',
                'Contact Email',
                'Invoice Status',
                'Billed Amount',
                'Invoice Date']}
              filename="market_ar_report.csv"
              transformData={(row) => [
                row.invoiceId,
                row.workOrderId,
                row.franchiseName,
                row.contactName,
                row.contactEmail,
                row.status,
                row.billedAmount / 100,
                toCstCdt(row.createdAt),
              ]}
            />
            )}
          </div>
          <div className={styles.gridItem}>
            <div className={styles.overviewCaption}>
              <h5>Get Market Payout Reports</h5>
            </div>
            {vansReport && vansReport.length > 0 && (
            <Select
              label=""
              loading={false}
              id="Vans Select"
              placeholder="Select a Van"
              value={selectedVansReport.join(',')}
              onChange={handleVanChangeReport}
            >
              <option key="all" value="all">
                All Vans
              </option>
              {vansReport.map((van) => (
                <option key={van.id} value={van.id.toString()}>
                  {selectedVansReport.includes(van.id)
                    ? `${van.name} (currently selected)`
                    : van.name}
                </option>
              ))}
            </Select>
            )}
            { payoutReports && payoutReports.length > 0 && (
            <CSVDownloadButton
              data={payoutReports}
              headers={[
                'ID',
                'Franchise Name',
                'Van Name',
                'Start Date',
                'End Date',
                'Total Sales',
                'Subtotal Sales',
                'Total Revenue',
                'Subtotal Revenue',
                'Total Tax',
                'Transaction Fee',
                'Marketing Fee',
                'Royalty Fee',
                'Insurance',
                'Technology Fee',
                'Third-Party Fleet Fee',
                'National Fleet Fee',
                'Credit Go Oil',
                'Refunds',
                'Cash Payments',
                'Unreported Revenue',
                'Total Amount',
                'Status',
              ]}
              filename="payout_report.csv"
              transformData={(row) => [
                row.id,
                row.franchise,
                row.van,
                toCstCdt(row.startDate),
                toCstCdt(row.endDate),
                row.totalSales || 0,
                row.subtotalSales || 0,
                row.totalRevenue || 0,
                row.subtotalRevenue || 0,
                row.totalTax || 0,
                row.transactionFee || 0,
                row.marketingFee || 0,
                row.royaltyFee || 0,
                row.insurance || 0,
                row.technologyFee || 0,
                row.thirdPartyFleetFee || 0,
                row.nationalFleetFee || 0,
                row.creditGoOil || 0,
                row.refunds || 0,
                row.cashPayments || 0,
                row.unreportedRevenue || 0,
                row.total || 0,
                row.status,
              ]}
            />
            )}
          </div>
          <div className={styles.gridItem}>
            {' '}
          </div>
          <div className={styles.gridItem}>
            {' '}
          </div>
        </div>
      </div>
    </div>
  );
}
