import React from 'react';
import Button from './Button';
import styles from '../sass/components/Analytics.module.scss';

interface CSVDownloadButtonProps {
  data: any[];
  headers: string[];
  filename: string;
  transformData: (row: any) => any[];
}

export default function CSVDownloadButton({
  data,
  headers,
  filename,
  transformData,
}: CSVDownloadButtonProps) {
  const generateCSV = () => {
    if (data.length === 0) return;

    const rows = data.map(transformData);

    let csvContent = `${headers.join(',')}\n`;
    rows.forEach((rowArray) => {
      csvContent += `${rowArray.join(',')}\n`;
    });

    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = filename;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <div className={styles.content}>
      <Button
        type="button"
        onClick={generateCSV}
        variant="primary"
        className={styles.downloadButton2}
      >
        Download
        {' '}
        {filename}
      </Button>
    </div>
  );
}
