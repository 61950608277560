import { useLazyQuery, useMutation } from '@apollo/client';
import { useEffect, useState } from 'react';
import styles from '../sass/components/InventoryPart.module.scss';
import {
  GET_VAN_TOOLS,
  INCREASE_VAN_TOOL_QUANTITY,
  DECREASE_VAN_TOOL_QUANTITY,
} from '../util/gql';
import { useAppSelector } from '../app/hooks';
import useAuthentication from '../hooks/useAuthentication';
import { VanTool, VanTools } from '../interfaces/Inventory';
import Modal from './Modal';
import Button from './Button';
import EmptyList from './EmptyList';
import TextInput from './TextInput';
import { ReactComponent as Search } from '../images/search.svg';
import IncreaseInventoryToolContainer from './IncreaseInventoryToolContainer';
import DecreaseInventoryToolContainer from './DecreaseInventoryToolContainer';
import ErrorModalContainer from './CompleteModal';

interface InventoryToolProps {
  vanId: number;
  emptyListMsg: string;
}

export default function InventoryTool(
  { emptyListMsg, vanId }: InventoryToolProps,
) {
  const [inventoryTools, setInventoryTools] = useState<VanTools>([]);
  const [
    increaseInventoryToolModalOpen,
    setIncreaseInventoryToolModalOpen,
  ] = useState(false);

  const [
    decreaseInventoryToolModalOpen,
    setDecreaseInventoryToolModalOpen,
  ] = useState(false);
  const [errorModal, setErrorModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [selectedVanToolId,
    setSelectedVanToolId,
  ] = useState<string>('');
  const [selectedToolName, setSelectedToolName] = useState<string>('');

  const [filter, setFilter] = useState<string>('');
  const [inputValue, setInputValue] = useState<string>('');
  const [currentPage, setCurrentPage] = useState(1);
  const [total, setTotal] = useState<number>(0);
  const [currentCount, setCurrentCount] = useState<number>(0);

  const { loggedIn: isLoggedIn } = useAuthentication();
  const currentUser = useAppSelector((
    state,
  ) => state.auth.currentUser);
  const isManager = currentUser.roles?.includes('manager');
  const isTester = currentUser.roles?.includes('tester');
  const isTechnician = currentUser.roles?.includes('technician');

  const [
    getVanTools,
    { loading }] = useLazyQuery(GET_VAN_TOOLS, {
    onCompleted: (data) => {
      const inventoryToolList = data?.getVanTools.inventoryTools;
      setCurrentCount(currentCount + inventoryToolList.length);
      setTotal(data?.getVanTools.total);
      setInventoryTools((prevItems) => [...prevItems, ...inventoryToolList]);
    },
    onError: (err) => {
      setErrorMessage(`Failed to load inventory tools: ${err.message}`);
      setErrorModal(true);
    },
    fetchPolicy: 'network-only',
  });

  const fetchInventoryTools = () => {
    setInventoryTools([]);
    setCurrentPage(1);
    setCurrentCount(0);
    setTotal(0);
    getVanTools({
      variables: {
        vanId,
        pageNumber: 1,
        filter,
      },
    });
  };

  const [increaseVanToolQuantity] = useMutation(INCREASE_VAN_TOOL_QUANTITY, {
    onCompleted: () => fetchInventoryTools(),
    onError: (error) => {
      if (error instanceof Error) {
        setErrorMessage(`Error increasing quantity: ${error.message}`);
      } else {
        setErrorMessage('An unknown error occurred while increasing quantity.');
      }
      setErrorModal(true);
    },
  });

  const [decreaseVanToolQuantity] = useMutation(DECREASE_VAN_TOOL_QUANTITY, {
    onCompleted: () => fetchInventoryTools(),
    onError: (error) => {
      if (error instanceof Error) {
        setErrorMessage(`Error decreasing quantity: ${error.message}`);
      } else {
        setErrorMessage('An unknown error occurred while decreasing quantity.');
      }
      setErrorModal(true);
    },
  });

  useEffect(() => {
    if (isLoggedIn) {
      fetchInventoryTools();
    }
  }, [isLoggedIn, vanId]);

  const openIncreaseInventoryToolModal = (
    inventoryVanToolId: string,
    toolName: string,
  ) => {
    setSelectedVanToolId(inventoryVanToolId);
    setSelectedToolName(toolName);
    setIncreaseInventoryToolModalOpen(true);
  };

  const openDecreaseInventoryToolModal = (
    inventoryVanToolId: string,
    toolName: string,
  ) => {
    setSelectedVanToolId(inventoryVanToolId);
    setSelectedToolName(toolName);
    setDecreaseInventoryToolModalOpen(true);
  };

  const handleIncreaseInventory = async (
    data: {
      quantity: number;
     },
  ) => {
    if (selectedVanToolId) {
      const inventoryVanToolId = parseInt(selectedVanToolId, 10);
      try {
        await increaseVanToolQuantity({
          variables: {
            vanToolId: inventoryVanToolId,
            increment: data.quantity,
          },
        });
      } catch (error) {
        if (error instanceof Error) {
          setErrorMessage(`Error increasing quantity: ${error.message}`);
        } else {
          setErrorMessage(
            'An unknown error occurred while increasing quantity.',
          );
        }
        setErrorModal(true);
      } finally {
        setIncreaseInventoryToolModalOpen(false);
        setSelectedVanToolId('');
        setSelectedToolName('');
      }
    }
  };

  const handleDecreaseInventory = async (
    data: {
      reason: string;
     },
  ) => {
    if (selectedVanToolId) {
      const inventoryVanToolId = parseInt(selectedVanToolId, 10);
      try {
        await decreaseVanToolQuantity({
          variables: {
            vanToolId: inventoryVanToolId,
            reason: data.reason,
          },
        });
      } catch (error) {
        if (error instanceof Error) {
          setErrorMessage(`Error decreasing quantity: ${error.message}`);
        } else {
          setErrorMessage(
            'An unknown error occurred while decreasing quantity.',
          );
        }
        setErrorModal(true);
      } finally {
        setDecreaseInventoryToolModalOpen(false);
        setSelectedVanToolId('');
        setSelectedToolName('');
      }
    }
  };

  const handleLoadMore = () => {
    const nextPage = currentPage + 1;
    setCurrentPage(nextPage);

    getVanTools({
      variables: {
        vanId,
        pageNumber: nextPage,
        filter,
      },
    });
  };

  const handleSearch = () => {
    fetchInventoryTools();
  };

  const handleCloseErrorModal = () => {
    setErrorModal(false);
    setErrorMessage(null);
  };

  const IncreaseInventoryToolModal = () => (
    <Modal
      open={increaseInventoryToolModalOpen}
      onClose={() => setIncreaseInventoryToolModalOpen(false)}
    >
      <IncreaseInventoryToolContainer
        vanToolId={selectedVanToolId}
        toolName={selectedToolName}
        onClose={() => setIncreaseInventoryToolModalOpen(false)}
        onSubmit={handleIncreaseInventory}
      />
    </Modal>
  );

  const DecreaseInventoryToolModal = () => (
    <Modal
      open={decreaseInventoryToolModalOpen}
      onClose={() => setDecreaseInventoryToolModalOpen(false)}
    >
      <DecreaseInventoryToolContainer
        vanToolId={selectedVanToolId}
        toolName={selectedToolName}
        onClose={() => setDecreaseInventoryToolModalOpen(false)}
        onSubmit={handleDecreaseInventory}
      />
    </Modal>
  );

  const CompleteErrorModal = () => (
    <ErrorModalContainer
      onClose={handleCloseErrorModal}
      open={errorModal}
      title="Inventory Error"
      message={errorMessage || 'An unexpected error occurred'}
      currentReader={null}
      invoiceId={null}
    />
  );

  const content = inventoryTools.length > 0 ? (
    <>
      <table className={styles.inventoryTable}>
        <thead>
          <tr>
            <th>Name</th>
            <th>
              Quantity
            </th>
            <th>{isManager || isTester ? 'Actions' : ''}</th>
          </tr>
        </thead>
        <tbody>
          {inventoryTools.map((item: VanTool) => {
            const { quantity } = item;
            return (
              <tr key={item.id}>
                <td>{item.tool.name}</td>
                <td>{quantity}</td>
                <td>
                  {(isManager || isTester || isTechnician) && (
                  <div style={{ display: 'flex', gap: '8px' }}>
                    <Button
                      variant="secondary"
                      onClick={
                      () => openIncreaseInventoryToolModal(
                        String(item.id),
                        item.tool.name,
                      )
}
                    >
                      +
                    </Button>
                    <Button
                      variant="secondary"
                      inactive={item.quantity === 0}
                      onClick={
                      () => openDecreaseInventoryToolModal(
                        String(item.id),
                        item.tool.name,
                      )
}
                    >
                      -
                    </Button>
                  </div>
                  )}
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
      {currentCount < total && (
        <div className={styles.loadMoreButtonContainer}>
          <Button
            variant="secondary"
            onClick={handleLoadMore}
            className={styles.loadMoreButton}
          >
            Load more
          </Button>
        </div>
      )}
    </>
  ) : (
    <EmptyList msg={emptyListMsg} />
  );

  return (
    <div className={styles.itemsList}>
      <div className={styles.searchDiv}>
        <div className={styles.searchBar}>
          <div className={styles.magnifyingGlass}>
            <Search />
          </div>
          <TextInput
            placeholder="Search Tool by Name"
            value={inputValue}
            onChange={(e) => {
              setInputValue(e.currentTarget.value);
              setFilter(e.currentTarget.value);
            }}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                handleSearch();
              }
            }}
          />
        </div>
        <Button
          variant="primary"
          onClick={handleSearch}
          className={styles.searchButton}
        >
          Search
        </Button>
        {errorModal && <CompleteErrorModal />}
        {increaseInventoryToolModalOpen && <IncreaseInventoryToolModal />}
        {decreaseInventoryToolModalOpen && <DecreaseInventoryToolModal />}
      </div>
      {loading ? <p className={styles.loading}>Loading...</p> : content}
    </div>
  );
}
