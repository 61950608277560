import React from 'react';
import {
  PieChart, Pie, Cell, Tooltip, Legend,
} from 'recharts';

interface PieChartProps {
    title: string;
    data: { name: string; value: number }[];
    colors: string[];
  }

export default function PieChartComponent(
  { title, data, colors }: PieChartProps,
) {
  return (
    <div style={{ textAlign: 'center' }}>
      <h3>{title}</h3>
      <PieChart width={400} height={250}>
        <Pie data={data} dataKey="value" nameKey="name" outerRadius={100} label>
          {data.map((entry, index) => (
            <Cell key={entry.name} fill={colors[index % colors.length]} />
          ))}
        </Pie>
        <Tooltip />
        <Legend />
      </PieChart>
    </div>
  );
}
