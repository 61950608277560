import React from 'react';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from 'recharts';

interface BarConfig {
  key: string;
  color: string;
  name: string;
  stackId?: string;
}

interface BarChartProps {
  data: any[];
  xKey: string;
  bars: BarConfig[];
  title?: string;
}

export default function ReusableBarChart({
  data, xKey, bars, title,
}: BarChartProps) {
  return (
    <div style={{ textAlign: 'center' }}>
      {title && <h3>{title}</h3>}
      <ResponsiveContainer width="100%" height={400}>
        <BarChart
          data={data}
          margin={{
            top: 20, right: 30, left: 20, bottom: 5,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey={xKey} tick={{ fontSize: 12 }} />
          <YAxis />
          <Tooltip />
          <Legend />

          {bars.map((bar) => (
            <Bar
              key={bar.key}
              dataKey={bar.key}
              fill={bar.color}
              name={bar.name}
              stackId={bar.stackId}
            />
          ))}
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
}
